import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import MenuLogoPOT from "../components/MenuLogoBlackPOT";
import { Section, Title, SubTitle } from "../components/Section";
import { Seo } from "../components/Seo";

// Head function
export const Head = ({ location }) => (
  <Seo pathname={location.pathname} />
);

const teamData = {
  anastasiia: {
    firstName: "Anastasiia",
    topic: "Mindset, communication, and strategizing",
    personIntro:
      "Three masters degrees and more than 15 years of professional experience. I help people to provide the right first impression, improve their communication and strategic skills.",
    linkedInRef: "https://www.linkedin.com/in/anastasiia-tsarenko/",
  },
  tyna: {
    firstName: "Kristyna",
    topic: "Informational support and state agencies",
    personIntro:
      "Experience from public administration and non-profits in several European countries. Find the right sources and information. Connect people with the right opportunities that lead to the dream job.",
    linkedInRef: "https://www.linkedin.com/in/kristyna-wagnerova-81181aa6/",
  },
  noopur: {
    firstName: "Noopur",
    topic: "Career guidance and HR tips",
    personIntro:
      "IT recruiter with a passion for career coaching and helping people achieve their potential. I have worked in 3 different countries, and use my experience to guide those who want their dream job!",
    linkedInRef: "https://www.linkedin.com/in/noopurthatte/",
  },
  oleksii: {
    firstName: "Oleksii",
    topic: "Career change and shift to IT industry",
    personIntro:
      "Software engineer, scientific researcher, and technology enthusiast with a PhD in theoretical physics. I help people switch careers, in particular toward IT industry, using my own experience.",
    linkedInRef: "https://www.linkedin.com/in/oleksii-shevtsov/",
  },
  pritama: {
    firstName: "Pritama",
    topic: "Personal branding for job seekers",
    personIntro:
      "Digital Marketer with 7 years of experience. I help people customize and market their skills, expertise, and USP (unique selling point) to be the perfect candidate for any recruitment process.",
    linkedInRef: "https://www.linkedin.com/in/pritama-sarkar-6a592483/",
  },
  irina: {
    firstName: "Irina",
    topic: "Diversity and inclusion project manager",
    personIntro:
      "Specialised on migration and ethnic relations, I bring together individuals from different horizons and backgrounds and help them to communicate and to coordinate to achieve a shared goal.",
    linkedInRef: "https://www.linkedin.com/in/irina-a-widmer-824500142",
  },
  juliana: {
    firstName: "Juliana",
    topic: "Marketing and communication strategist",
    personIntro:
      "Communication and marketing professional with 7+ years of experience in brand strategic planning, go-to-market strategies, and content creation that helps brands stand out.",
    linkedInRef: "https://www.linkedin.com/in/marulandajuliana/",
  },
  dziyana: {
    firstName: "Dziyana",
    topic: "Nonprofit sector, Career coaching & mentorship",
    personIntro:
      "Passionate about gender equality and environmental issues. With experience in nonprofit sector, focuses on international development cooperation, event management, partnerships management, water resources protection.",
    linkedInRef: "https://www.linkedin.com/in/dziyanakatselkina/",
  },
  abhishek: {
    firstName: "Abhishek",
    topic: "Strategic partnership expert",
    personIntro:
      "Master's degree and 3+ years of professional experience. Exploring and creating win-win opportunities with the vision to mutually collaborate with engineering, consultancy, and recruiting companies.",
    linkedInRef: "https://www.linkedin.com/in/abhishekbala/",
  },
  sinisha: {
    firstName: "Sinisha",
    topic: "IT Specialist, Career mentorship & EU charity",
    personIntro:
      "Software developer with entrepreneurship experience giving back to the community through career mentorship for people with international backgrounds. During his free time, Sinisha is an activist for EU charity projects.",
    linkedInRef: "https://www.linkedin.com/in/sinisha-stojchevski-071447167/",
  },
  laurence: {
    firstName: "Laurence",
    topic: "Community engagement and self-improvement projects",
    personIntro:
      "Experienced in building communities and managing collaborative projects, I empower individuals with an international background to develop their entrepreneurship skills and take an active role in the local society.",
    linkedInRef: "https://www.linkedin.com/in/laurence-herpelinck-a8602726/",
  },
  rashmi: {
    firstName: "Rashmi",
    topic: "Partnership development specialist",
    personIntro:
      "A younger engineer with a strong set of skills pursuing Automotive Engineering at Chalmers University. My experience, training, and interests include the fields of Active safety and Vehicle Dynamics. Team-oriented, enthusiast, creative and is able to deal in various departments.",
    linkedInRef:
      "https://www.linkedin.com/in/rashmi-ganjagunte-somashekar-2b2952107/",
  },
  yao: {
    firstName: "Yao",
    topic: "Marketing and communication specialist",
    personIntro:
      "An enthusiastic communicator full of creativity and passion with experience in social media communication and brand strategy. Skilled in video promotion, inspiring storytelling. Exchanging energy with people and sharing life stories is my way of charging.",
    linkedInRef: "https://www.linkedin.com/in/yao-zhou-78b157182/",
  },
};

const HeroCard = ({
  mentorImg,
  firstName,
  topic,
  personIntro,
  linkedInRef,
}) => {
  return (
    <div className="flex-auto w-full mt-8 text-center sm:flex-initial sm:w-1/2 lg:w-1/3 xl:w-1/5 sm:p-2">
      <div className="w-full mx-auto mob:w-9/12 xl:w-full">
        <GatsbyImage
          image={mentorImg.childImageSharp.gatsbyImageData}
          alt={`${firstName} photo`}
          className="w-full rounded-img"
        />
      </div>
      <p className="mt-2 font-semibold text-orange-700 lg:text-lg">
        {firstName}
      </p>
      <p className="font-semibold text-orange-700 lg:text-lg">{topic}</p>
      <p className="mt-4 lg:text-lg">{personIntro}</p>
      <a
        href={linkedInRef}
        target="_blank"
        rel="noopener noreferrer"
        className="mt-4 text-orange-700 underline hover:text-blue-700 lg:text-lg"
      >
        Connect on LinkedIn
      </a>
    </div>
  );
};

HeroCard.propTypes = {
  mentorImg: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  personIntro: PropTypes.string.isRequired,
  linkedInRef: PropTypes.string.isRequired,
};

const IntroSection = ({ groupImg }) => (
  <Section>
    <Title>Who are we?</Title>
    <SubTitle>
      We are you. We faced the same situations, went through the same struggles,
      and experienced discomfort. We realized quickly that to survive and thrive
      in our own domains, we need to be creative and resourceful. That&apos;s
      what we want to share. Our stories, our insights, our connections, and our
      guidance.
    </SubTitle>
    <SubTitle>We may not be experts. But, we know what works.</SubTitle>
    <div className="mx-auto mt-10 text-center w-full mob:w-10/12 sm:w-8/12 md:w-7/12 lg:w-5/12 xl:w-4/12">
      <GatsbyImage
        image={groupImg.childImageSharp.gatsbyImageData}
        alt="Our team"
        className="rounded-img"
      />
      <p className="mt-2 lg:text-lg">Perform On Top founders</p>
    </div>
  </Section>
);

IntroSection.propTypes = {
  groupImg: PropTypes.object.isRequired,
};

const TeamBlockSection = ({ title, profileImages, profileData }) => {
  const mentorCards = Object.entries(profileImages).map(
    ([name, image], idx) => (
      <HeroCard
        key={`mentor-card-${idx}`}
        mentorImg={image}
        {...profileData[name]}
      />
    )
  );
  return (
    <Section>
      <Title>{title}</Title>
      <div className="flex flex-wrap justify-center">{mentorCards}</div>
    </Section>
  );
};

TeamBlockSection.propTypes = {
  title: PropTypes.string.isRequired,
  profileImages: PropTypes.object.isRequired,
  profileData: PropTypes.object.isRequired,
};

const AboutPage = ({ data }) => {
  const teamProfileImages = {
    anastasiia: data.anastasiiaImg,
    tyna: data.tynaImg,
    noopur: data.noopurImg,
    oleksii: data.oleksiiImg,
    pritama: data.pritamaImg,
    juliana: data.julianaImg,
    irina: data.irinaImg,
    dziyana: data.dziyanaImg,
    abhishek: data.abhishekImg,
    sinisha: data.sinishaImg,
    laurence: data.laurenceImg,
    rashmi: data.rashmiImg,
    yao: data.yaoImg,
  };

  return (
    <div className="flex flex-col items-center min-h-screen">
      <header className="w-full p-6 mob:px-8 md:px-10 lg:px-12">
        <nav>
          <MenuLogoPOT />
        </nav>
      </header>
      <main className="mt-4 md:mt-6 xl:mt-8">
        <IntroSection groupImg={data.groupImg} />
        <TeamBlockSection
          title="Our team"
          profileImages={teamProfileImages}
          profileData={teamData}
        />
      </main>
    </div>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutQuery = graphql`
  query AboutQuery {
    groupImg: file(relativePath: { eq: "group-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 640, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    anastasiiaImg: file(relativePath: { eq: "anastasiia.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    noopurImg: file(relativePath: { eq: "noopur.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    oleksiiImg: file(relativePath: { eq: "oleksii.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    pritamaImg: file(relativePath: { eq: "pritama.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    tynaImg: file(relativePath: { eq: "tyna.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    yaoImg: file(relativePath: { eq: "yao.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    julianaImg: file(relativePath: { eq: "juliana.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    irinaImg: file(relativePath: { eq: "irina.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    rashmiImg: file(relativePath: { eq: "rashmi.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    abhishekImg: file(relativePath: { eq: "abhishek.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    laurenceImg: file(relativePath: { eq: "laurence.png" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    dziyanaImg: file(relativePath: { eq: "dziyana.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    sinishaImg: file(relativePath: { eq: "sinisha.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 320, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`;
