import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import MenuLogoButton from "./MenuLogoButton";

const MenuLogoBlackPOT = () => {
  const { logoImg } = useStaticQuery(graphql`
    query MenuLogoBlackPOTQuery {
      logoImg: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 250, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <MenuLogoButton logoImg={logoImg} altTxt="Perform On Top logo black" />
  );
};

export default MenuLogoBlackPOT;
